body {
  background-color: #232323;
}

.glitch {
  font: {}

  text-decoration: none;
  text-transform: uppercase;

  transform: translate(-50%, -50%);
  margin: 0;
  color: #fff;
  letter-spacing: 2px;

  &:before,
  &:after {
    display: block;
    content: attr(data-glitch);
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .8;
  }

  &:after {
    color: #1DCFDC;
    z-index: -2;
  }

  &:before {
    color: #0ff;
    z-index: -1;
  }

  &:hover {
    &:before {
      animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both 5
    }

    &:after {
      animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both 5
    }
  }
}

@media only screen and (max-width: 400px) {
  .glitch {}
}


@keyframes glitch {
  0% {
    transform: translate(0)
  }

  20% {
    transform: translate(-5px, 5px)
  }

  40% {
    transform: translate(-5px, -5px)
  }

  60% {
    transform: translate(5px, 5px)
  }

  80% {
    transform: translate(5px, -5px)
  }

  to {
    transform: translate(0)
  }
}