$light: #ffffff;
$dark: lighten(#000000, 8%);
$glow: #0ba9ca;

html {
  height: 100%;
}

.glow {
  margin-top: 100px;
  text-align: center;
  span {
    color: $light;
    text-shadow: 
      0 0 2px $light,
      0 0 10px $light,
      0 0 20px $glow,
      0 0 30px $glow,
      0 0 40px $glow,
      0 0 50px $glow;
    animation: blink 1.5s infinite alternate;
  }
  .major {
    display: block;
    font-size: 60px;
  }
  
  @media (max-width: 768px) {
    .major {
      display: block;
      font-size: 40px;
    }
  }
  

}
@keyframes blink {
  0%   {}
  100% { 
    text-shadow: 
      0 0 2px $light,
      0 0 10px $light,
      0 0 20px $light,
      0 0 40px $glow,
      0 0 70px $glow,
      0 0 80px $glow;
  }
}