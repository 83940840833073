button.active-tab {
    --glow-color: rgb(217, 176, 255);
    --glow-spread-color: rgba(191, 123, 255, 0.5);
    /* Decreased opacity */
    --enhanced-glow-color: rgb(231, 206, 255);
    --btn-color: rgb(100, 61, 136);
    border: .25em solid var(--glow-color);
    /* padding: 2px 5px; */
    color: var(--glow-color);
    font-size: 15px;
    font-weight: bold;
    background-color: var(--btn-color);
    border-radius: 3px;
    outline: none;
    box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 1em var(--glow-spread-color),
        inset 0 0 .75em .25em var(--glow-color);
    text-shadow: 0 0 .5em var(--glow-color);
    position: relative;
    transition: all 0.3s;
}

button.active-tab::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--glow-spread-color);
    filter: blur(1em);
    /* Decreased blur amount */
    opacity: .5;
    /* Decreased opacity */
    transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

button.active-tab:hover {
    color: var(--btn-color);
    background-color: var(--glow-color);
    box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 1.5em var(--glow-spread-color),
        /* Adjusted spread amount */
        inset 0 0 .75em .25em var(--glow-color);
}

button.active-tab:active {
    box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2.5em 1.5em var(--glow-spread-color),
        /* Adjusted spread amount */
        inset 0 0 .5em .25em var(--glow-color);
}

.card-box {
    --glow-color: rgba(160, 25, 181, 0.86);
    width: 200px;
    height: 200px;
    padding: 10px;
    color: #fff;
    font-size: 20px;
    border-radius: 8px;
    position: relative;
}

.card-box::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0 10px var(--glow-color);
    opacity: 1;
    transition: opacity 0.3s;
}

.card-box:hover::before {
    opacity: 1;
}

.card-box:hover {
    color: #fff;
    /* Adjust text color on hover if needed */
}

.card-box:active {
    box-shadow: inset 0 0 10px var(--glow-color);
}

::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1DCFDC;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}