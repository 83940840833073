*{
    margin:0;
    padding:0;
    transition:all 0.35s linear;
}
section{
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: row;
    flex-wrap:wrap;
    min-height:100vh;
    background-color: black;
}

.cardone{
    --color:rgb(46, 212, 212);
    --border-size: 1rem;
    --bg-color: #252525;
    position:relative;
    background-color: var(--bg-color);
    width: 280px;
    margin: 2rem;
    border-radius: var(--border-size);
    color: white;
    display: grid;
    place-content: center;
    padding: 3rem 2rem;
    isolation: isolate;
    overflow: hidden;

}

.cardone::before,
.cardone::after{
    content: "";
    position: absolute;
}
.cardone::before{
    width: 200%;
    height: 200%;
    background-image: conic-gradient(var(--color) 0deg,
    transparent 60deg,
    transparent 180deg,
    var(--color) 180deg,
    transparent 240deg
    );
    inset: -50%;
    z-index: -2;
    animation: border 8s linear infinite;
}
@keyframes border{
    to{
        transform: rotateZ(-360deg);
    }
}
.cardone::after{
    --inset: 0.25rem;
    background-color: #111;
    inset: var(--inset);
    border-radius: calc(
        var(--border-size) - var(--inset)
    );
    z-index:-1;
    transition: all 0.3s linear;
}
.cardone i {
    font-size: 4rem;
    margin-inline: auto;
    color: transparent;
    -webkit-text-stroke: thin var(--color);
}


