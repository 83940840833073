.swiper {
  width: 100%;
  height: 100%;
}
@media (max-width:768px){
    .swiper-button-prev:after {
        content: "\2039";
        position: absolute;
      top: -50px;
      padding-right: 10px;
      font-size: 70px;
    }
    .swiper-button-next:after {
        content: "\203A";
        position: absolute;
        top: -50px;
        padding-left: 10px;
      font-size: 70px;
    
    }
    
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
@media (min-width:768px){
.swiper-button-prev:after {
    content: "\2039";
    position: absolute;
  top: -50px;
  padding-left: 30px;
  font-size: 70px;
}
.swiper-button-next:after {
    content: "\203A";
    position: absolute;
    top: -50px;
    padding-right: 30px;
  font-size: 70px;

}
}