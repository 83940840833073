@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);



.eve-banner {
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    z-index: 1;
}

::-webkit-scrollbar {
    width: 0;
}


@media (max-width: 768px) {
    .eve-banner {
        background-position: center center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .eve-banner {
        background-position: center bottom;
    }
}

:root {
    --neon-text-color: #8A2BE2;

}


.eve-heading {
    font-size: 13rem;
    font-weight: 700;
    font-style: italic;
    color: #fff;
    padding: 4rem 6rem 5.5rem;
    text-transform: uppercase;
    animation: flicker 1.5s infinite alternate;
}

.eve-heading::-moz-selection {
    background-color: var(--neon-border-color);
    color: var(--neon-text-color);
}

.eve-heading::selection {
    background-color: var(--neon-border-color);
    color: var(--neon-text-color);
}

.eve-heading:focus {
    outline: none;
}

/* Animate neon flicker */
@keyframes flicker {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);

        box-:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);
    }

    20%,
    24%,
    55% {
        text-shadow: none;
        box-shadow: none;
    }
}

button.active-tab {
    --glow-color: rgb(217, 176, 255);
    --glow-spread-color: rgba(191, 123, 255, 0.5);
    /* Decreased opacity */
    --enhanced-glow-color: rgb(231, 206, 255);
    --btn-color: rgb(100, 61, 136);
    border: .25em solid var(--glow-color);
    /* padding: 2px 5px; */
    color: var(--glow-color);
    font-size: 15px;
    font-weight: bold;
    background-color: var(--btn-color);
    border-radius: 3px;
    outline: none;
    box-shadow: 0 0 .8em .15em var(--glow-color),
        0 0 3em 1em var(--glow-spread-color),
        inset 0 0 .6em .15em var(--glow-color);
    text-shadow: 0 0 .4em var(--glow-color);
    position: relative;
    transition: all 0.3s;
}

button.active-tab::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--glow-spread-color);
    filter: blur(.9em);

    opacity: .2;
    transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

button.active-tab:hover {
    color: var(--btn-color);
    background-color: var(--glow-color);
    box-shadow: 0 0 .8em .05em var(--glow-color),
        0 0 3em 1.5em var(--glow-spread-color),
        /* Adjusted spread amount */
        inset 0 0 .0em .0em var(--glow-color);
}

button.active-tab:active {
    box-shadow: 0 0 0.5em .05em var(--glow-color),
        0 0 2.5em 1.5em var(--glow-spread-color),
        /* Adjusted spread amount */
        inset 0 0 .4em .004em var(--glow-color);
}