

#app {}

#wrapper {
    text-align: center;
}

.sub {
    color: rgb(100, 220, 220);
    letter-spacing: 1em;
}

@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch {
    position: relative;
    color: white;
    font-weight: 400;
    animation: glitch-skew 1s infinite linear alternate-reverse;
    
}

.glitch::before {
    @include glitchCopy;
    left: 2px;
    text-shadow: -2px 0 #1DCFDC;
    clip: rect(44px, 450px, 56px, 0);
    font-weight: 400;
    animation: glitch-anim 5s infinite linear alternate-reverse;
}

.glitch::after {
    @include glitchCopy;
    left: -2px;
    font-weight: 400;
    text-shadow: -2px 0 #00fff9, 2px 2px #1DCFDC;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
    $steps: 20;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-skew {
    $steps: 10;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(10) - 5) + deg);
        }
    }
}