@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);


::-webkit-scrollbar {
    width: 0;
}




@media (max-width: 768px) {
    .workshop-banner {
        background-position: bottom center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .workshop-heading {
        background-position: center bottom;
    }
}



.workshop-heading {
    font-size: 13rem;
    font-weight: 700;
    font-style: italic;
    color: #fff;
    padding: 4rem 6rem 5.5rem;
    text-transform: uppercase;
    animation: flicker 1.5s infinite alternate;
    background-size: cover;
}

.workshop-heading::-moz-selection {
    background-color: var(--neon-border-color);
    color: var(--neon-text-color);
}

.workshop-heading::selection {
    background-color: var(--neon-border-color);
    color: var(--neon-text-color);
}

.workshop-heading:focus {
    outline: none;
}

/* Animate neon flicker */
@keyframes flicker {

    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {

        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);

        box-:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);
    }

    20%,
    24%,
    55% {
        text-shadow: none;
        box-shadow: none;
    }
}