@import url("https://fonts.cdnfonts.com/css/burn-rubber");

.about-bg {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  border: 2px solid transparent;
  border-image: linear-gradient(to right, #743ad5, #1DCFDC) 1;
}

.custom-play-button {
  background-image: linear-gradient(45deg, #743ad5, #1DCFDC);
  /* border: 3px solid transparent; */
  border-image: linear-gradient(to right, #743ad5, #1DCFDC) 1;
}

::-webkit-scrollbar {
  width: 0;
}

.video-clip {
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: scale(0.8);
  margin-bottom: 5px;
  background-image:linear-gradient(
    to right bottom,
    #1DCFDC
  );;
}

img.neon-img {
  animation: neon linear infinite 3s;
}

@keyframes neon {
  0% {
    transform: translateY(20px);
  }

  25% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  75% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(20px);
  }
}

.grad-text {
  color: transparent;
  background: linear-gradient(45deg, #1DCFDC, #4329d6);
  -webkit-background-clip: text;
  font-size: 70px;
}

.icons {
  position: relative;
}

.clip {
  clip-path: polygon(20% 0%, 80% 0%, 100% 120%, 0% 120%);

  background-image: linear-gradient(45deg, #743ad5, #1DCFDC);
}

.icon-bg {
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #743ad5, #1DCFDC) 1;
}

.opacity {
  opacity: 0;
  transition-duration: 0.5s;
}

.icon-bg:hover .opacity {
  opacity: 1;
}

.link-font {
  font-size: 20px;
  transition-duration: 0.5s;
}

.icon-bg:hover .link-font {
  font-size: 25px;
}

.icons-home {
  /* background-image: url("../assets/img/about-home.jpg"); */
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .icons-home {
    background-position: center center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .icons-home {
    background-position: center bottom;
  }
}
