#modal {
  max-height: 500px;
}
#container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
input::placeholder {
  color: #8d8d8d;
}
