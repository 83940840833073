button.btn-glow {
    --glow-color: rgb(217, 176, 255);
    --glow-spread-color: rgba(191, 123, 255, 0.5);
    --enhanced-glow-color: rgb(231, 206, 255);
    --btn-color: rgb(100, 61, 136);
    border: .25em solid var(--glow-color);
    /* padding: 2px 5px; */
    color: var(--glow-color);
    font-size: 15px;
    font-weight: bold;
    background-color: var(--btn-color);
    border-radius: 3px;
    outline: none;
    box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 1em var(--glow-spread-color),
        inset 0 0 .75em .25em var(--glow-color);
    text-shadow: 0 0 .5em var(--glow-color);
    position: relative;
    transition: all 0.3s;
}

button.btn-glow::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--glow-spread-color);
    filter: blur(1em);
    /* Decreased blur amount */
    opacity: .5;
    /* Decreased opacity */
    transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
}

button.btn-glow:hover {
    color: var(--btn-color);
    background-color: var(--glow-color);
    box-shadow: 0 0 1em .25em var(--glow-color),
        0 0 4em 1.5em var(--glow-spread-color),
        /* Adjusted spread amount */
        inset 0 0 .75em .25em var(--glow-color);
}

button.btn-glow:active {
    box-shadow: 0 0 0.6em .25em var(--glow-color),
        0 0 2.5em 1.5em var(--glow-spread-color),
        /* Adjusted spread amount */
        inset 0 0 .5em .25em var(--glow-color);
}
