@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-gradient: linear-gradient(
    to right bottom,
    #1DCFDC
  );
  /* font-family: Arial, Helvetica, sans-serif;
  font-family: "BURN-RUBBER", sans-serif; */
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;

  color: white;
  /* background-color: #000; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #000;
}

.bg-gradient {
  background-image: var(--primary-gradient);
}

.design_btn {
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 35%, 90% 0%);
  transition: transform 1s cubic-bezier(0, 0, 0.2, 1);
  backface-visibility: hidden;
}

.design_btn:hover {
  transform: translateY(-5px);
}

.header {
  @apply absolute w-[95%] max-w-[1000px] mx-auto top-5 left-1/2 transform -translate-x-1/2 py-3 px-10 rounded-full flex items-center justify-between backdrop-filter backdrop-blur-sm bg-opacity-20 bg-black bg-clip-padding backdrop-saturate-100;
}

.text_input {
  border-bottom: 1px solid white;
}

.text_input:focus {
  border-color: transparent;
  border-bottom-color: #eee;
  outline: none;
}

.text_input::placeholder {
  color: #888;
}

select option {
  background-color: #121212;
}

.animate-fade {
  animation: fade 0.6s ease-in-out;
}

.nav_heading:hover h1,
.nav_heading.active h1 {
  text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff;
}

.nav_heading:hover,
.nav_heading.active {
  transform: scale(1.05);
}

@keyframes fade {
  0%,
  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.animate-show {
  animation: showFirst 0.5s ease-in-out;
}

@keyframes showFirst {
  0%,
  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*  */
/*  */
/*  */
/* Card Design  index .*/
.team-content {
  position: relative;
  margin-top: -15px;
}

.team-content::before {
  background: var(--primary-gradient);
  clip-path: polygon(57% 0%, 100% 0%, 45% 100%, 0% 100%);
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 40px;
  height: 60px;
  right: 5px;
  top: 0;
}

.team-content .white-shape {
  background-color: #fff;
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
  border-radius: 3px;
  position: absolute;
  width: 60%;
  height: 60px;
  left: -10px;
  top: -10px;
}

.team-content h3 {
  background: var(--primary-gradient);
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 30%);
}

.team-content h3:before {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 6px
  );
  background-size: 4px 4px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.team-content h3 a {
  color: #fff;
  z-index: 1;
}

.team-content h4 {
  background-color: #ddd;
  clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
  color: #0c0c35;
  width: 60%;
  height: 25px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0;
  margin: -10px 0px 0px 20px;
}

.team-content:after {
  background: #ddd;
  clip-path: polygon(57% 0%, 100% 0%, 45% 100%, 0% 100%);
  border-radius: 3px;
  content: "";
  position: absolute;
  width: 40px;
  height: 60px;
  right: -16px;
  top: 0;
}

.team-thumb {
  min-height: 280px;
}

.team-thumb img {
  width: 100%;
  max-width: 200px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.team-thumb .shape {
  background: var(--primary-gradient);
  clip-path: polygon(0% 0%, 80% 0, 100% 10%, 100% 100%, 0 100%);
  width: 150px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.2s ease-in-out;
}

.team-thumb .shape.shape-1 {
  clip-path: polygon(0% 0%, 85% 0%, 100% 15%, 100% 80%, 85% 100%, 0% 100%);
  width: 200px;
  height: 70%;
  left: 20%;
  z-index: 1;
  opacity: 0.8;
}

.team-thumb .shape.shape-2 {
  height: 100%;
  left: 30%;
  opacity: 0.7;
}

.team-thumb .shape.shape-3 {
  height: 90%;
  z-index: 2;
  opacity: 0.4;
  left: 30px;
}

.team-thumb .shape.shape-4 {
  height: 60%;
  left: 15px;
  opacity: 0.6;
}

.team-item:hover .shape.shape-1 {
  height: 75%;
  transition-delay: 200ms;
}

.team-item:hover .shape.shape-2 {
  height: 105%;
  transition-delay: 150ms;
}

.team-item:hover .shape.shape-3 {
  height: 95%;
  transition-delay: 100ms;
}

.team-item:hover .shape.shape-4 {
  height: 65%;
  transition-delay: 50ms;
}

/* Footer */
.footer_container {
  position: fixed;
  bottom: 0;
  transform: rotate(0deg);
  left: 0;
  right: 0;
  background: url("../public/foot.webp") no-repeat top center fixed;
  height: 90px;
  background-size: 100% 100px;
  z-index: 20;
  /* bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: url("../public/headerImg.png") no-repeat top center fixed;
  height: 90px;
  background-size: 100% 100px; */
}


/* sss */
/* new changes */

/* 
.footer_container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: url("../public/headerImg.png") no-repeat bottom center;
  background-size: 100% 60px;
  transform: rotate(180deg); /* Rotate the background image
  z-index: -1;
}  */

@media (max-width: 768px) {
  .footer_container {
    height: 40px;
    background: url("../public/mobilefooter.webp") no-repeat bottom center fixed;
    background-size: 100% 40px;
  }
}

/* Header */
.header_container {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: url("../public/Group104.webp") no-repeat top center fixed;
  height: 100px;
  background-size: 100% 100px;
}

@media (max-width: 768px) {
  .header_container {
    height: 80px;
    background: url("../public/assets/headerMobile.webp") no-repeat top center
      fixed;
    background-size: 100% 80px;
  }
}

.scroll_in {
  overflow-y: scroll;
}

.scroll_in::--webkit-scrollbar {
  display: none;
}

.a_box {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  position: fixed;
}

.a_box ul li {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  list-style: none;
  opacity: 0;
  background: radial-gradient(rgb(152, 65, 255), transparent, transparent);
  bottom: 0;
  left: 10vw;
  animation: animate 15s linear infinite;
}

.a_box ul li:nth-child(2) {
  left: 37vw;
  animation-delay: 2s;
}

.a_box ul li:nth-child(3) {
  left: 25vw;
  animation-delay: 4s;
}

.a_box ul li:nth-child(4) {
  left: 60vw;
  animation-delay: 6s;
}

.a_box ul li:nth-child(5) {
  left: 95vw;
  animation-delay: 8s;
}

.a_box ul li:nth-child(6) {
  left: 35vw;
  animation-delay: 10s;
}

.a_box ul li:nth-child(7) {
  left: 85vw;
  animation-delay: 12s;
}

.a_box ul li:nth-child(8) {
  left: 55vw;
  animation-delay: 14s;
}

.a_box ul li:nth-child(9) {
  left: 75vw;
  animation-delay: 16s;
}

@keyframes animate {
  0% {
    transform: scale(0);
    opacity: 1;
    bottom: 0;
  }

  100% {
    transform: scale(7);
    opacity: 0;
    bottom: 100vh;
  }
}

.contact-logo {
  background-image: var(--primary-gradient);
}

.audio-bg {
  background-image: var(--primary-gradient);
}

::-webkit-scrollbar {
  width: 0;
}

.send-btn {
  background-image: var(--primary-gradient);
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 35%, 90% 0%);
  width: 120px;
  height: 40px;
  margin-top: 20px;
}

.gradient-bg {
  background-image: var(--primary-gradient);
}

.side-num {
  position: relative;
}
.side-num::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 30px;
  width: 2px;
  height: 2px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5),
    -2px -2px 4px rgba(255, 255, 255, 0.5);
}
.side-num::before {
  content: "";
  position: absolute;
  top: -500%;
  width: 2px;
  left: 50%;
  height: 100px;
  background-color: white;
}

.side-head {
  position: relative;
}
.side-head::after {
  content: "";
  position: absolute;
  bottom: -500%;
  width: 2px;
  left: 50%;
  height: 100px;
  background-color: white;
}

::-webkit-scrollbar {
  width: 0;
}
.soc-icon {
  transition-duration: 0.5s;
}
.icon-bg:hover .soc-icon {
  color: #1DCFDC;
}

.main-heading {
  font-weight: 700;
  font-style: italic;
  color: #fff;
  text-transform: uppercase;
  animation: flicker 1.5s infinite alternate;
}

.main-heading::-moz-selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.main-heading::selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.main-heading:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2rem var(--neon-text-color), 0 0 4rem var(--neon-text-color),
      0 0 6rem var(--neon-text-color), 0 0 8rem var(--neon-text-color),
      0 0 10rem var(--neon-text-color);

    box-: 0 0 0.5rem #fff, inset 0 0 0.5rem #fff,
      0 0 2rem var(--neon-border-color), inset 0 0 2rem var(--neon-border-color),
      0 0 4rem var(--neon-border-color), inset 0 0 4rem var(--neon-border-color);
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}
::selection {
  background-color: #1DCFDC;
  color: white;
}

.comeBtn {
  border: none;
  outline: none;
  color: #fff !important;
  background: #221c3e;
  background: transparent;
  position: relative;
  z-index: 0;
  padding: 19px 32px 19px 32px !important;
  border: 1px solid white;
}

.comeBtnActive {
  border: none;
  outline: none;
  color: #fff !important;
  background: #221c3e;
  position: relative;
  z-index: 0;
  padding: 19px 32px 19px 32px !important;
  border: 1px solid white;
}

.comeBtn:before,
.comeBtnActive::before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
}

.comeBtn:active:after,
.comeBtnActive:active::after {
  background: transparent;
}

.comeBtn:hover:before,
.comeBtnActive:hover::before {
  opacity: 1;
}

.comeBtn:hover::after,
.comeBtnActive:hover::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #221c3e;
  left: 0;
  top: 0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.memos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0.7rem;
  grid-template-areas:
    " memo-1 memo-2 memo-3 memo-3 "
    " memo-1 memo-4 memo-5 memo-6 "
    " memo-8 memo-7 memo-7 memo-6 "
    " memo-9 memo-10 memo-11 memo-12 ";
}

.memos img {
  width: 100%;
  height: 15rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  background-color: grey;
}

.memos a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;

  /* background-color: rgba(0, 0, 0, .8);
transition: opacity .2s; */
}
.memos img {
  width: 100%;
  height: 100%;
}

.memos a:hover::before {
  opacity: 1;
}

.memo-1 {
  grid-area: memo-1;

  min-height: 30.7rem;
}

.memo-2 {
  grid-area: memo-2;
}

.memo-3 {
  grid-area: memo-3;
}

.memo-4 {
  grid-area: memo-4;
}

.memo-5 {
  grid-area: memo-5;
}

.memo-6 {
  grid-area: memo-6;

  min-height: 30.7rem;
}

.memo-7 {
  grid-area: memo-7;
}

.memo-8 {
  grid-area: memo-8;
}
.memo-9 {
  grid-area: memo-9;
}
.memo-10 {
  grid-area: memo-10;
}
.memo-11 {
  grid-area: memo-11;
}
.memo-12 {
  grid-area: memo-12;
}
@media (max-width: 1024px) {
  .memos {
    grid-template-areas:
      " memo-3 memo-3 memo-3 memo-3 "
      " memo-2 memo-2 memo-1 memo-1 "
      " memo-4 memo-4 memo-5 memo-5 "
      " memo-6 memo-7 memo-7 memo-7 "
      " memo-8 memo-8 memo-8 memo-8 "
      " memo-9 memo-9 memo-10 memo-10 "
      " memo-11 memo-11 memo-12 memo-12 ";
  }

  .memos a {
    height: 20rem;
  }

  .memo-1 {
    min-height: 20rem;
  }

  .memo-6 {
    min-height: 20rem;
  }
}

@media (max-width: 512px) {
  .memos {
    grid-template-areas:
      " memo-1 memo-1 memo-1 memo-1 "
      " memo-2 memo-2 memo-3 memo-3 "
      " memo-4 memo-4 memo-4 memo-4 "
      " memo-5 memo-5 memo-5 memo-5 "
      " memo-6 memo-6 memo-7 memo-7 "
      " memo-9 memo-9 memo-9 memo-9 "
      " memo-12 memo-12 memo-11 memo-11 "
      " memo-10 memo-10 memo-10 memo-10 "
      " memo-8 memo-8 memo-8 memo-8 ";
  }

  .memos a {
    height: 10rem;
  }
  .memo-1,
  .memo-6,
  .memo-7 {
    min-height: 15rem;
    height: 15rem;
  }
}
/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
}

/* Card container */
.card {
  position: relative;
  max-width: 100%;
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
}
/* Global ::before */
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #1DCFDC;
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
  left: 0px;
  top: -4.5px;
  bottom: -4.5px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
  .card:nth-child(odd)::before {
    top: -5px;
    bottom: -5px;
  }
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  color: gray;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px;
}

/* Title of the card */
.title {
  color: #1DCFDC;
  position: relative;
}

/* Timeline dot  */

/* text right if the card is even  */
.card:nth-child(even) > .info > .title {
  text-align: right;
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .title::before {
  left: -45px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .title::before {
  right: -45px;
}

/* hero title section */
.navbar_robo {
  z-index: 20000;
}

.robo-img {
  transition: all;
  animation: float 5s ease 0.2s infinite forwards;
}
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

/* preloader css */

.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1e1c1c;
  z-index: 1000;
  transition: opacity 0.5s linear;
}
.preloader-hidden {
  opacity: 0;
  pointer-events: none;
}
.preloader-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1.5s linear;
}

.imagesenterclick {
  transition: linear;
  animation: zoom 7s linear 0.2s 1 forwards;
}
.enter-btn {
  transition: all;
  animation: blink 2s linear 0.3s infinite forwards;
}

@keyframes zoom {
  0% {
    transform: scale(100%);
  }

  100% {
    transform: scale(1000%);
    opacity: 0.5;
    display: hidden;
  }
}
.preloader {
  position: absolute;
  z-index: 50;
}

#particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#particles canvas {
  z-index: -2;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 768px) {
  #particles canvas {
    opacity: 0.8;
  }
}

/* Title styling */
.titleSection {
  color: transparent;
  cursor: default;
  transition: color 0.3s linear;
  -webkit-text-stroke: 2px #0a69a5; /* Outline the text in aqua color */
  text-shadow: 0 0 20px #0a69a5, 0 0 40px #0a69a5;
}

/* Light effect */
.light-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(
    circle at var(--x) var(--y),
    transparent 5%,
    rgba(30, 38, 42, 0.95) 15%
  );
}

.section {
  position: relative;
  min-height: 100vh;
  background: #1e262a;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 70px;
}

/* Text color change on light hover */

.section:hover .titleSection {
  color: #0a69a5;
  mix-blend-mode: difference;
}

.back {
  z-index: -2;
  height: 100%;
  width: 100%;
  position: absolute;
}

.spline-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

/* Mobile*/
@media screen and (max-width: 767px) {
  .spline-container {
    height: 100vh;
    width: 100%;
    margin-top: 30%;
    overflow: hidden;
  }
  .canvas {
    transform: scale(100px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
}

/* Animation delays for mobile */
.mobile-delay-1 {
  animation-delay: 0.1s;
}
.mobile-delay-2 {
  animation-delay: 0.2s;
}
.mobile-delay-3 {
  animation-delay: 0.3s;
}
.mobile-delay-4 {
  animation-delay: 0.4s;
}
.mobile-delay-5 {
  animation-delay: 0.5s;
}
.mobile-delay-6 {
  animation-delay: 0.6s;
}
.mobile-delay-7 {
  animation-delay: 0.7s;
}
.mobile-delay-8 {
  animation-delay: 0.8s;
}
.mobile-delay-9 {
  animation-delay: 0.9s;
}
.mobile-delay-10 {
  animation-delay: 1s;
}
.mobile-delay-11 {
  animation-delay: 1.1s;
}



@keyframes waves {
  0%,
   100% {
     clip-path: polygon(
       0% 45%,
       16% 44%,
       33% 50%,
       54% 60%,
       70% 61%,
       84% 59%,
       100% 52%,
       100% 100%,
       0% 100%
     );
   }
 
   50% {
     clip-path: polygon(
       0% 60%,
       15% 65%,
       34% 66%,
       51% 62%,
       67% 50%,
       84% 45%,
       100% 46%,
       100% 100%,
       0% 100%
     );
   } 
 }