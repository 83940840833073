
  /* Title styling */
  .title {
    color: transparent;
    cursor: default;
    transition: color 0.3s linear;
    -webkit-text-stroke: 2px #0A69A5; /* Outline the text in aqua color */
    text-shadow: 0 0 20px #0A69A5, 0 0 40px #0A69A5;
  }

  /* Light effect */
  .light-effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(
        circle at var(--x) var(--y),
        transparent 10%,
        rgba(30, 38, 42, 0.95) 20%
    );
  }

  .section {
    position: relative;
    min-height: 100vh;
    background: #1e262a;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 70px;
  }


  @media screen and (max-width: 768px) {
    .title {
        -webkit-text-stroke: 1px aqua;
        text-shadow: 0 0 10px aqua, 0 0 20px aqua;
    }

    .light-effect {
        background: radial-gradient(
            circle at var(--x) var(--y),
            transparent 5%,
            rgba(30, 38, 42, 0.95) 15%
        );
    }
  }
  /* Text color change on light hover */

  .section:hover .title {
    color: aqua;
    mix-blend-mode: difference;
  }

  .back{
    z-index: -2;
    height: 100%;
    width: 100%;
    position:absolute;
  }