@import url(https://fonts.googleapis.com/css?family=Exo+2:200i);

.error {
    background-color: red;
    width: 100px;
    height: 100px;
  }
.mainBox{
  border: 1.5px solid;
  border-image-source: linear-gradient(180deg, #2db7c4 0%, #1DCFDC 100%);
}

