.input-group {
    position: relative;
    width: 100%;
  }
  
  .input-group input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #aaa;
    background: transparent;
    color: white;
    font-size: 1rem;
    outline: none;
    padding: 1rem 0;
    margin-top: 6px;
  }
  
  .input-group label {
    position: absolute;
    left: 0;
    top: 30%;
    transform: translateY(-30%);
    color: #aaa;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
  
  .input-group input:focus + label,
  .input-group input:not(:placeholder-shown) + label {
    top: -2px;
    font-size: 0.8rem;
    color: cyan;
  }
  
  .input-group input:focus {
    border-bottom: 1px solid cyan;
  }
  